<!--
 * @Description: 设备数据查询
 * @Author: ChenXueLin
 * @Date: 2021-10-18 17:14:53
 * @LastEditTime: 2022-07-20 14:06:29
 * @LastEditors: ChenXueLin
-->

<!-- 设备数据 -->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <div class="second-change-type">
        <div class="switch-page-button">
          <el-button
            round
            :class="[clickIndex == index ? 'activeButton' : '']"
            v-for="(btn, index) in btnList"
            :key="index"
            @click="switchPage(index)"
            >{{ btn.name }}</el-button
          >
        </div>
        <!-- <div class="right-btn">
          <el-button type="primary">轨迹偏移分析</el-button>
          <el-button type="primary">刷新定位区域</el-button>
          <el-button type="primary">清除定位区域</el-button>
          <el-button type="primary">刷新轨迹</el-button>
          <el-button type="primary">显示地图</el-button>
        </div> -->
      </div>
      <!-- 搜索头部 start -->
      <div class="table-title" ref="tableTitle">
        <section class="search-wrapper fixed-section search-triangle">
          <el-form
            class="search-list"
            ref="searchForm"
            :model="searchForm"
            style="padding-top:0px"
          >
            <el-form-item class="search-item--2" prop="createTime">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="创建时间"
                ref="effectiveDatePicker"
                v-model="searchForm.createTime"
                title="创建时间"
                :picker-options="pickerOptions('searchForm.createTime')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="GPS开始时间"
                end-placeholder="GPS结束时间"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item
              v-if="clickIndex === 0"
              class="search-item--1"
              prop="accStatus"
            >
              <el-input
                v-model="searchForm.status"
                placeholder="设备状态"
                title="设备状态"
              ></el-input>
            </el-form-item>
            <el-form-item
              v-if="clickIndex === 0"
              class="search-item--1"
              prop="accStatus"
            >
              <e6-vr-select
                v-model="searchForm.accStatus"
                :data="accStatusEnum"
                placeholder="ACC状态"
                title="ACC状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item
              v-if="clickIndex === 0"
              class="search-item--1"
              prop="loStatus"
            >
              <e6-vr-select
                v-model="searchForm.loStatus"
                :data="loStatusEnum"
                placeholder="定位状态"
                title="定位状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item
              v-if="clickIndex === 0"
              class="search-item--1"
              prop="lineStatus"
            >
              <e6-vr-select
                v-model="searchForm.lineStatus"
                :data="lineStatusEnum"
                placeholder="天线状态"
                title="天线状态"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item
              class="search-item--1"
              prop="stopType"
              v-if="clickIndex === 3"
            >
              <e6-vr-select
                v-model="searchForm.stopType"
                :data="stopTypeEnum"
                placeholder="停车类型"
                title="停车类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item
              v-if="clickIndex === 4"
              class="search-item--1"
              prop="camid"
            >
              <e6-vr-select
                v-model="searchForm.camid"
                :data="camChannelEnum"
                placeholder="摄像头路数"
                title="摄像头路数"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item
              v-if="clickIndex === 4"
              class="search-item--1"
              prop="cameraTypeId"
            >
              <e6-vr-select
                v-model="searchForm.cameraTypeId"
                :data="camTypeEnum"
                placeholder="拍照类型"
                title="拍照类型"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </section>
      </div>

      <section class="table-wrapper">
        <el-table border height="600px" :data="tableData" highlight-current-row>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'handle'">
                <el-button type="text" @click="openPictrueDialog(row)">
                  查看照片
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 start -->
        <section class="pagination-wrapper fixed-section">
          <el-pagination
            :page-size.sync="searchForm.pageSize"
            :current-page.sync="searchForm.pageIndex"
            :page-sizes="pageSizes"
            :layout="layout"
            :total="total"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </section>
        <!-- 分页 end -->
      </section>
    </div>
    <!-- 查看照片弹框 -->
    <el-dialog
      v-dialogDrag
      title="查看照片"
      :visible.sync="dialogVisibel"
      width="620px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="edit-customer-dialog"
    >
      <div class="pic-content">
        <el-image
          :src="dialogData.picUrl || dialogData.picData"
          class="picClass"
          fit="fit"
        ></el-image>
      </div>
      <el-form
        ref="form"
        label-width="140px"
        label-position="right"
        disabled
        class="editForm content-form-items"
      >
        <el-form-item label="车牌号："></el-form-item>
        <el-form-item label="拍照时间：">
          {{ dialogData.picTime }}
        </el-form-item>
        <el-form-item label="写入时间：">
          {{ dialogData.writeTime }}
        </el-form-item>
        <el-form-item label="摄像头路数：">
          {{ dialogData.camid }}
        </el-form-item>
        <el-form-item label="拍照类型：">
          {{ dialogData.cameraTypeId }}
        </el-form-item>
        <el-form-item label="照片回码：">
          {{ dialogData.photoBackCode }}
        </el-form-item>
      </el-form>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleClose">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import {
  findDownList,
  getEquipHistoryTrackList,
  getEquipTemperature,
  getEquipOilCalc,
  getEquipStopTime,
  getEquipPicture,
  getEquipPictureUrl
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "installPhoto",
  data() {
    return {
      dialogVisibel: false,
      dialogLoading: false,
      dialogData: {}, //弹框数据
      clickIndex: 0,
      btnList: [
        { name: "历史轨迹查询" },
        { name: "温度查询" },
        { name: "油量查询" },
        { name: "停车数据查询" },
        { name: "拍照数据查询" }
      ],
      stopTypeEnum: [], //停车类型枚举
      camChannelEnum: [], //摄像头路数枚举
      camTypeEnum: [], //拍照类型枚举
      accStatusEnum: [], //acc状态枚举
      loStatusEnum: [], //定位状态枚举
      lineStatusEnum: [], //天线状态枚举
      timePickerResetList: ["createTime"], // base mixin的配置项 需要重置的timePicker refname
      trajectoryColumnData: [
        {
          fieldName: "commids",
          display: true,
          fieldLabel: "中心识别码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "gpsTime",
          display: true,
          fieldLabel: "GPS时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "写入时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "speed",
          display: true,
          fieldLabel: "速度",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "odometer",
          display: true,
          fieldLabel: "里程",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "lon",
          display: true,
          fieldLabel: "经度",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "lat",
          display: true,
          fieldLabel: "纬度",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accStatusName",
          display: true,
          fieldLabel: "ACC状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "lineStatusName",
          display: true,
          fieldLabel: "天线状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "loStatusName",
          display: true,
          fieldLabel: "定位状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "status",
          display: true,
          fieldLabel: "设备状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "direction",
          display: true,
          fieldLabel: "方向",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //轨迹表头
      temperatureColumn: [
        {
          fieldName: "rcvdt",
          display: true,
          fieldLabel: "GPS时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "temperature",
          display: true,
          fieldLabel: "温度一",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "temperature2",
          display: true,
          fieldLabel: "温度二",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "temperature3",
          display: true,
          fieldLabel: "温度三",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "temperature4",
          display: true,
          fieldLabel: "温度四",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "coldStatus",
          display: true,
          fieldLabel: "冷机状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //温度表头
      oilColumn: [
        {
          fieldName: "gpsTime",
          display: true,
          fieldLabel: "GPS时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "odometer",
          display: true,
          fieldLabel: "里程",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oilcalc",
          display: true,
          fieldLabel: "油量1",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "ohm",
          display: true,
          fieldLabel: "欧姆值",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "oilcalc2",
          display: true,
          fieldLabel: "油量2",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "ohm2",
          display: true,
          fieldLabel: "欧姆值2",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "speed",
          display: true,
          fieldLabel: "速度",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "accStatus",
          display: true,
          fieldLabel: "ACC状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "loStatus",
          display: true,
          fieldLabel: "定位状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //油量查询
      stopCarColumn: [
        {
          fieldName: "rcvTime",
          display: true,
          fieldLabel: "GPS时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "wid",
          display: true,
          fieldLabel: "中心识别码",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "theType",
          display: true,
          fieldLabel: "停车类型",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stopSecs",
          display: true,
          fieldLabel: "停车总时间（秒）",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "stopBegin",
          display: true,
          fieldLabel: "停车标志",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "ssn",
          display: true,
          fieldLabel: "停车流水号",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "odometer",
          display: true,
          fieldLabel: "里程（KM）",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "lon",
          display: true,
          fieldLabel: "经度",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "lat",
          display: true,
          fieldLabel: "纬度",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "statusStr",
          display: true,
          fieldLabel: "车辆状态",
          width: 220,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //停车数据查询
      takePictures: [
        {
          fieldName: "picTime",
          display: true,
          fieldLabel: "拍照时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "camid",
          display: true,
          fieldLabel: "摄像头路数",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "cameraTypeId",
          display: true,
          fieldLabel: "拍照类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "photoBackCode",
          display: true,
          fieldLabel: "照片回码",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "writeTime",
          display: true,
          fieldLabel: "写入时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "handle",
          display: true,
          fieldLabel: "操作",
          width: 160,
          disabled: false,
          fixed: "right",
          align: "center"
        }
      ], //拍照数据查询
      tableData: [], // 表格数据
      columnData: [], //当前 表头
      // 注意因为这里的参数为多个接口公用 所以在请求之前做了个过滤，在这新加的参数必须在getQueryParam函数添加逻辑
      searchForm: {
        createTime: [],
        beginTime: "",
        endTime: "",
        status: "", // 设备状态
        accStatus: "", // ACC状态
        loStatus: "", // 定位状态
        lineStatus: "", // 天线状态
        stopType: "", // 停车类型
        camid: "", // 摄像头道数
        cameraTypeId: "", // 拍照类型
        sceneId: "",
        pageIndex: 1,
        pageSize: 20
      },
      total: 0
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  components: {},
  watch: {
    // 创建开始时间
    "searchForm.createTime": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.createTime = this.searchForm.createTime.reverse();
        }
        this.searchForm.beginTime = val[0] || "";
        this.searchForm.endTime = val[1] || "";
      }
    }
  },
  methods: {
    //点击二级切换
    switchPage(idx) {
      this.clickIndex = idx;
      this.tableData = [];
      switch (this.clickIndex) {
        case 0:
          this.columnData = this.trajectoryColumnData;
          this.queryApi = getEquipHistoryTrackList;
          break;
        case 1:
          this.columnData = this.temperatureColumn;
          this.queryApi = getEquipTemperature;
          break;
        case 2:
          this.columnData = this.oilColumn;
          this.queryApi = getEquipOilCalc;
          break;
        case 3:
          this.columnData = this.stopCarColumn;
          this.queryApi = getEquipStopTime;
          break;
        default:
          this.columnData = this.takePictures;
          this.queryApi = getEquipPicture;
          break;
      }
      this.handleSearch();
    },
    init(sceneId) {
      if (!sceneId) {
        return;
      }
      this.searchForm.sceneId = sceneId;
      this.handleSearch();
    },
    async queryList() {
      if (!this.searchForm.beginTime || !this.searchForm.endTime) {
        this.$message.warning("请选择GPS时间范围");
        return;
      }
      if (this.searchForm.endTime - this.searchForm.beginTime > 7 * 86400000) {
        this.$message.warning("GPS时间范围不能超过7天");
        return;
      }
      try {
        this.loading = true;
        let res = await this.queryApi(this.getQueryParam());
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取基础信息(枚举)
    async initData() {
      try {
        let promiseList = [
          findDownList([
            "stopType",
            "camChannel",
            "camType",
            "accStatus",
            "loStatus",
            "lineStatus"
          ])
        ];
        let [findDownListRes] = await Promise.all(promiseList);
        this.stopTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.stopType"
        });
        this.camChannelEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.camChannel"
        });
        this.camTypeEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.camType"
        });
        this.accStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.accStatus"
        });
        this.loStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.loStatus"
        });
        this.lineStatusEnum = this.getFreezeResponse(findDownListRes, {
          path: "data.lineStatus"
        });
      } catch (error) {
        printError(error);
      }
    },
    getQueryParam() {
      let key = {
        mustParam: ["beginTime", "endTime", "pageIndex", "pageSize", "sceneId"],
        0: ["status", "accStatus", "loStatus", "lineStatus"],
        3: ["stopType"],
        4: ["camid", "cameraTypeId"]
      };
      let param = {};
      key.mustParam.map(item => {
        param[item] = this.searchForm[item];
      });
      if (key[this.clickIndex]) {
        key[this.clickIndex].map(item => {
          param[item] = this.searchForm[item];
        });
      }
      return param;
    },
    // 打开弹窗
    openPictrueDialog(row) {
      this.dialogVisibel = true;
      if (row) {
        this.getPictrueUrl(row);
      }
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisibel = false;
      this.dialogData = {};
    },
    async getPictrueUrl(row) {
      try {
        this.dialogLoading = true;
        let res = await getEquipPictureUrl({
          sceneId: this.searchForm.sceneId,
          picTime: row.picTime
        });
        if (res.code === "OK") {
          if (res.data.picData) {
            res.data.picData = `data:image/png;base64,${res.data.picData}`;
          }
          this.dialogData = {
            ...res.data,
            ...row
          };
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    }
  },
  created() {
    this.columnData = this.trajectoryColumnData;
    this.queryApi = getEquipHistoryTrackList;
    this.initData();
    let times = new Date().getTime();
    this.searchForm.createTime = [times - 86400000, times];
  }
};
</script>
<style lang="scss" scoped>
.switch-page-button {
  width: auto;
  padding: 0;
  margin: 0;
}

.second-change-type {
  margin: 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
.pic-content {
  text-align: center;
  overflow: hidden;
  .picClass {
    height: 300px;
    min-width: 100px;
    margin: 10px auto;
    overflow: hidden;
  }
}
</style>
